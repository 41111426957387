import React,  { useEffect }  from 'react';
import './Styles/Process.css';

	function Process(){

		useEffect(() => {
			document.documentElement.scrollTop = 0;
		});

	    return(
	        <>
			<div className="Container-fulid col-12">
						<div className="row col-max pt-2  ">
							<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
										Process
										</div>
								</div>
								<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Scheduling</span> a time to meet the client in their convenient time.</p>
             </div>
</div>
</div>

<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Meeting</span> the client to discuss their requirements at the scheduled time.</p>
             </div>
</div>
</div>

<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Requirements</span> are gathered from the client to estimate the project.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Quotation</span> of the project will be sent to the client for reviewing.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Approval</span> will be secured from the client for the given quotation.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Initial Payment</span> is accredited by the client.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Procurements</span> are initiated as part of possession for the required materials from the vendors.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Work Start - </span> The project/work will be started after purchased materials are received.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Work-in Progress -</span>The project/work will be progressed day by day.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Interim Payment</span> is a part payment of the project cost. </p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Continue Work - </span>The project/work will be continued.</p>
             </div>
</div>
</div>
<div className="row">
<div className="col-12 mx-auto container">
             <div>
<p className='processContent'>&diams;&nbsp;<span className='processContentTitle'>Work Completion - </span>The project/work will be completed and handed over to client.</p>
             </div>
</div>
</div>


										
										<div>
											<img src="../Image/Process/Processfinal.jpg" className="img-fluid" alt="Process"></img>
										</div>
										
								
								</div>
								</div>
								</div>
	        </>
	    )
	}
	
	export default Process;