import React ,  { useEffect }  from 'react';
import {NavLink } from 'react-router-dom';
	
	function Services(){

		useEffect(() => {
			document.documentElement.scrollTop = 0;
		});

	    return(
	        <>
			<div className="Container-fulid col-12">
						<div className="row  pt-2  ">
							<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
										Industrial and Commercial Piping Systems
											
										</div>
								</div>

								<div className='d-flex'>
									<p className='homeAboutIntroContent'>
									Expertized in the installation of all kinds of industrial Pipline such as Compressed Air System(HVAC) Chill Water & AHU Piping Systems, Exhaust Piping Systems, DG Installation and Piping, Machine Exhaust ducting.
								
									</p>
									
								</div>
								<div className='d-flex'>
									<p className='homeAboutIntroContent'>
									Expert in the installation of all kinds of commercial Fire Protection System ( Malls and Shopping Complexes ).
									</p>
									
								</div>
								<div className='d-flex'>
								   <p className='homeAboutIntroContent'>
								 Design, Supply, Fabrication, Installation, Testing & Commissioning of complete Piping System as per the standards.
									</p>
									</div>
									<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								    Cost-effective, Timely Service and Excellent customer support.
									</p>
									</div>
									</div>
									<div className="col-md">
						<NavLink to="/Services" >
							<img src="../Image/Services/Industrialpipingsystem.jpg" className="img-fluid rounded"  width="500px;"></img>
							</NavLink>
						</div>
						
									</div>
									<div className="row  pt-2  ">
									<div className="col-md">
									<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
										Fire Protection Systems
											
										</div>
								</div>
								<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								Actively committed to offer a wide Spectrum of Fire Fighting Systems.
									</p>
									</div>
									<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								Supported by highly skilled and experienced professionals to Design, Supply, Fabrication, Install, Test & Commission  complete Fire Fabrication Systems as per the Standards.
									</p>
									</div>
									</div>
									<div className="col-md">
						<NavLink to="/Services" >
							<img src="../Image/Services/Firepipingsystem.jpg" className="img-fluid rounded"  width="500px;"></img>
							</NavLink>
						</div>
						</div>
						<div className="row  pt-2  ">
									<div className="col-md">
									<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
										Structural Work
											
										</div>
								</div>
								<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								Providing the best and fastest solutions for structural Design of Industrial Piping as per the Standards.
									</p>
									</div>
									<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								Services are handled by qualified and experienced engineers.
									</p>
									</div>
								
									<div className='d-flex'>
								<p className='homeAboutIntroContent'>
								Committed approach towards delivering quality services at affordable Prices.
									</p>
									</div>
									</div>
									<div className="col-md p-4">
						<NavLink to="/Services" >
							<img src="../Image/Services/Structralwork.jpg" className="img-fluid rounded"  width="500px;"></img>
							</NavLink>
						</div>
								
								
						</div>
						
					</div>
	            
	        </>
	    )
	}
	
	export default Services;
