import React,  { useEffect }  from 'react';
import {NavLink } from 'react-router-dom';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,CardFooter
  } from 'reactstrap';

	
function Contact(){
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
	    return(
	        <>
			<div className="Container-fulid col-12">
						<div className="row col-max pt-2  ">
							<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
                                        Contact
											
										</div>
								</div>
								</div>
								</div>

                                <div className="container mt-4" >
                
                <div className="row justify-content-center "  >
                    <div className="p-2" >
                    <Card className="border border-secondary" style={{height: '28rem'}}>
                    
                        <CardTitle className="p-3 bg-secondary text-white cardTitleFontFamily">Contact</CardTitle>
                        
                        <NavLink to="/Contact" >
                                <CardImg top width="100%" src="../Image/Contact/Mobile.jpg" alt="Mobile" />
                                </NavLink>
                        <CardBody>
                        <CardText className="cardFontFamily text-center"> <b> Joseph E. Vijay </b>  +91 9791389329    </CardText>
                        <CardText className="cardFontFamily text-center"> <b> E. Jones Vasanth  </b> +91 9894872020 </CardText>
                        </CardBody>
                        
                    </Card>
                    
                    </div>

                    <div className="p-2" >
                    <Card className="border border-secondary" style={{height: '28rem' }}>
                        <CardTitle className="p-3 bg-secondary text-white cardTitleFontFamily">E-Mail</CardTitle>
                        <NavLink to="/Contact" >
                        <CardImg top width="100%" src="../Image/Contact/Mail.jpg" alt="Mail" />
                        </NavLink>
                        <CardBody>
                        <CardText className="cardFontFamily text-center">jjassociates6@gmail.com</CardText> 
                        
                        </CardBody>

                        
                    </Card>
                    </div>



                    <div className="p-2"   >
                    <Card className="border border-secondary" style={{height: '28rem'}}> 
                        <CardTitle className="p-3 bg-secondary text-white cardTitleFontFamily fs-5">Location</CardTitle>
                        <NavLink to="/Contact" >
                        <CardImg top width="100%" src="../Image/Contact/Location .jpg" alt="Location" />
                        </NavLink>
                        <CardBody>
                        
                        <CardText className=" text-center">1/5 - 2nd Street,</CardText> 
                        <CardText className=" text-center">Thirumalai Nagar,</CardText> 
                        <CardText className="cardFontFamily text-center">Old Perungalathur,</CardText> 
                        <CardText className="cardFontFamily text-center">Chennai - 600063,</CardText>
                        <CardText className="cardFontFamily text-center">Tamil Nadu.</CardText> 

                        </CardBody>
                        
                    </Card>
                    </div>
                </div>
            </div>
                                </div>
                                </>
	    )
	}
    export default Contact;

                                