import React,  { useEffect } from 'react';
	
	function About(){
		useEffect(() => {
			document.documentElement.scrollTop = 0;
		});

	    return(
	        <>
			<div className="contanier-fulid col-12 ">
					<div className="row   pt-2 ">
						<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
										  About  
											
										</div>
								</div>
								<div className='d-flex'>
									<p className='homeAboutIntroContent'>
										JJ Associates is an industrial supporting firm  established in 2015 with the objective to provide and offer high quality of services in Fire Protection System, Industrial Piping System, Structural Work, HVAC Chill Water Piping System, AHU Piping, Structural Fabrication and Erection.
									</p>
									
								</div>
								<div className='d-flex'>
								<p className='homeAboutIntroContent'>
										we also do complete maintenance work for Industrial Piping,
										Civil and Structural Works.
									</p>
									</div>
									<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
												Experience
											
										</div>
								</div>
								<div className='d-flex'>
								<p className='homeAboutIntroContent'>
									Over 15 Years of Experience in the field of Industrial Piping System, Fire Protection System and Structural Works (50+ Projects have been Completed successfully).
									</p>
									</div>


								</div>
						</div>
					</div>
				
				</>
	    )
	}
	
	export default About;