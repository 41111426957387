import React,  { useEffect } from 'react';

import './Styles/Clients.css'
	
	function Clients(){

		useEffect(() => {
  			document.documentElement.scrollTop = 0;
		  });


	    return(
	        <>
			<div className="container-fluid col-12" >
                <div className="row">
				<div className="col-12 mx-auto container">
                     <div className="container justify-content-center mt-4">
					 <div ><h1></h1>
					 </div>
				     </div>
					 <div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
												Clients
										</div>
								</div>
								<div className='d-flex mt-2'>
										<div className="homeAboutIntroContentTitle">
										<a className="btn btn-secondary" href="#Viewallclients" role="button">View All Clients  </a>
										</div>
								</div>
								
					 <div className="container mt-4" >
                    	<div className="row justify-content-center"  >
							<div className="c_borderLeftColor mt-3 border-rounded">
									<img src="./Image/Clients/HanonSystemsIndia.jpg" className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/eta.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3 border-rounded">
									<img src="./Image/Clients/GKN Driveline.jpg" className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/ACME.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/JLL.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3 border-rounded">
									<img src="./Image/Clients/M Ware.jpg" className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/Milky Mist.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/Mondelez.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3 border-rounded">
									<img src="./Image/Clients/Sterling and Wilson Solar Limited 2.jpg" className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/Toray.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/ennore port limited.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/HDC.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/HIS.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/Lotte Chemical.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/Sundram Fasteners.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div style={{width:3}}></div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/TIMKEN.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
								<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/RMZ.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
				<div className="c_borderLeftColor mt-3">
									<img src="./Image/Clients/NETAFIM.jpg"  className="img-thumbnail"  width="160" height="90" ></img>
								</div>
                </div>
					</div>
					</div>
				</div>
			
			 <div id="Viewallclients"><div className="container-fulid col-12"></div>
					<div className="bg-white">
						<div className=" row   col-lg">
						<div className="text-start" >
						<div className='d-flex'>
						               <div className='ClientsHeaderDiv homeleftDiv'></div>
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
															
		 <span>
			<p className='homeAboutIntroContent'>
			  &rarr;&nbsp;Hanon systems India Pvt. Ltd. Chennai.<span className="homeAboutIntroContentTitle ml-3"><h6>(Fire Protection Systems  Hydrant pipeline,Sprinkler Systems, Maintenance Activities, Compressed Airline, Nitrogen Pipeline, Furnace Exhaust Ducting)</h6></span>
			</p>
			 <p className='homeAboutIntroContent'>
			   &rarr; &nbsp;Faery Estates Kandanchavadi.<span className="homeAboutIntroContentTitle"><h6>(Fire Protection System, Utility Lines & Water line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			   &rarr;&nbsp;JLL Cognizant Technology Solutions, Siruseri.<span className="homeAboutIntroContentTitle"><h6>(Civil Work)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			   &rarr;&nbsp;RMZ lnfotech Pvt. Ltd.<span className="homeAboutIntroContentTitle"><h6>(Fire Protection System Water line & Utility line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Brigade-World Trade Center For Sterling and Wilson Pvt. Ltd.<span className="homeAboutIntroContentTitle"><h6>. (Fire Protection System water line, Utility line & Chill water line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Toray Industry India Pvt.Ltd. SRI CITY -TADHA.<span className="homeAboutIntroContentTitle"><h6>(Fire Fighting Water line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Hoseong Electric India Pvt. Ltd.<span className="homeAboutIntroContentTitle"><h6>(Fire Fighting Water line & Utility line) </h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Hyundai Steels India Pvt. Ltd.<span className="homeAboutIntroContentTitle"><h6>(Fire Fighting Water line & Air line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Hyundai EP India Pvt. Ltd. SRI CITY, AP.<span className="homeAboutIntroContentTitle"><h6>(Fire Fighting & Compassed Air line systems)</h6></span>
			</p>
		     <p className='homeAboutIntroContent'>
				&rarr;&nbsp;Lotte Chemicals - Haryana. <span className="homeAboutIntroContentTitle"><h6>. (Fire Fighting & Utility system)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Mcwane India Pvt. Ltd. SRI CITY.  <span className="homeAboutIntroContentTitle"><h6>. (Chill water Pipeline & Utility line work)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp; Milky Mist Diary Products Pvt. Ltd. ,Perundurai.  <span className="homeAboutIntroContentTitle"><h6>. (Boilers Process Piping & Utility line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;TVS Sundaram fastners,Mahendra City. <span className="homeAboutIntroContentTitle"><h6>. (Fire fighting Water line, Compressor line & Utility line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Timken, Mahendra City.  <span className="homeAboutIntroContentTitle"><h6>. (Fire Fighting Water line, Compressor Pipeline & Utility Pipeline) </h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Netafim, Mahendra City.  <span className="homeAboutIntroContentTitle"><h6>.  (Fire Protection System Water line, Airline & Pump room Piping) </h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Chennai Airport, Meenambakkam.  <span className="homeAboutIntroContentTitle"><h6>. (DG Exhaust line, Water line, Diesel line & Structural)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Ascenders, Tharamani.  <span className="homeAboutIntroContentTitle"><h6>. (Chiller line & Pump Room Piping)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Ennore Port, Atthipet <span className="homeAboutIntroContentTitle"><h6>. (Fire Protection System, Water line & Structural line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Hotel Leela Palace.  <span className="homeAboutIntroContentTitle"><h6>. (DG Exhaust line, Boiler erection & Piping)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;TVH Groups, MRC Nagar.  <span className="homeAboutIntroContentTitle"><h6>. (DG Exhaust line)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;LBR Hotels And Hospitalty Services Pvt. Ltd. <span className="homeAboutIntroContentTitle"><h6>. (DG Exhaust line & Water line) </h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;SPCL.  <span className="homeAboutIntroContentTitle"><h6>. (DG Exhaust line, Structural Work & Plumbing Shaft)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;Mondelez Foods India Pvt. Ltd. Sree City, Tada. <span className="homeAboutIntroContentTitle"><h6> (Fire Process Piping)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;ETA Engineering Pvt. Ltd. PROZONE Mall, Alliance mall developers, Coimbatore. <span className="homeAboutIntroContentTitle"><h6>. (Fire Protection System)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;KADIMI Steels India Pvt. Ltd. For ACME Fabconlnd Pvt. Ltd. Sriperumbudur. <span className="homeAboutIntroContentTitle"><h6>. (Fire Fighting)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;GKN Drivelines, ACME Fabcontnd Pvt. Ltd.oragadam.  <span className="homeAboutIntroContentTitle"><h6>. (Fire Protection system Maintenance & Utility Pipeline)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;TVS Infrastructure Pvt. Ltd. (Amazon Warehouse) METEC.  <span className="homeAboutIntroContentTitle"><h6>.  (Fire Fighting Pipeline)</h6></span>
			</p>
			<p className='homeAboutIntroContent'>
			    &rarr;&nbsp;INDOSPACE-lndustrial & Logistic Parks. For METEC.  <span className="homeAboutIntroContentTitle"><h6>.(Fire Protection System(Sprinklers & Internal Hydrants)Pipeline Work</h6></span>
			</p>
		 </span> 
			</div>
		</div>
		</div>
		</div>
	</div>
	</div>
</div>
	        </>
	    )
	}
	
	export default Clients;