import React,  { useEffect }  from 'react';
import {NavLink } from 'react-router-dom';
import './Styles/Home.css'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button,CardFooter
  } from 'reactstrap';

	
	function Home(){
		useEffect(() => {
			document.documentElement.scrollTop = 0;
		});
	    return(
	        <>
			  <div className="contanier-fulid  col-12 ">
					<div className="row   pt-2 ">
						<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
												Industrial Supporting Firm
											
										</div>
								</div>

								<div className='d-flex'>
									<p className='homeAboutIntroContent'>
									JJ Associates is an industrial supporting firm  established in 2015 with the objective to provide and offer high quality services in Fire Protection System, Industrial Piping System, Structural Work, HVAC Chill Water Piping System, AHU Piping, Structural Fabrication and Erection.
										<span className='pt-2'>
											<NavLink to="/About" >
												<img  src="../Image/Home/Readmoreicon00.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"  /> 
											</NavLink>
										</span>
									</p>
								</div>
								
						</div>
						<div className="col-md">
						<NavLink to="/About" >
							<img src="./Image/Home/About icon.jpg" className="img-fluid"  width="500px;"></img>
							</NavLink>
						</div>
						
					</div>	
					<div className="row pt-2">
					<div className="col-auto">
						<div className='d-flex'>

									<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
									<div className="homeClientsHeaderDiv"></div>
									<div className='homeAboutIntroContentTitle'>
										Our Services
									</div>
									
							</div>
					</div>
					<div className="container mt-4" >
                
                    		<div className="row justify-content-center "  >
								<div className="p-2" >
								<Card className="border border-warning" style={{height: '28rem'}}>
									<CardTitle className="p-3 bg-warning text-white cardTitleFontFamily">Industrial Piping System</CardTitle>
									
									<NavLink to="/Services" >
									        <CardImg top width="100%" src="../Image/Services/Industrialpipingsystem.jpg" alt="Card image cap" />
											</NavLink>
									<CardBody>
									<CardText className="cardFontFamily">Expertized in the installation of all kinds of industrial Pipline such as Compressed Air System(HVAC) Chill Water & AHU Piping Systems, Exhaust Piping Systems.... </CardText>
								    </CardBody>
									<CardFooter style={{backgroundColor:"#ffffff"}}>
											<NavLink to="/Services" >
												<img  src="../Image/Home/Readmoreicon00.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"  /> 
											</NavLink>
									</CardFooter>
								</Card>
								</div>

								<div className="p-2" >
								<Card className="border border-danger " style={{height: '28rem' }}>
									<CardTitle className="p-3 bg-danger text-white cardTitleFontFamily">Fire Production System</CardTitle>
									<NavLink to="/Services" >
									<CardImg top width="100%" src="../Image/Services/Firepipingsystem.jpg" alt="Card image cap" />
									</NavLink>
									<CardBody>
									<CardText className="cardFontFamily">Actively committed to offer a wide Spectrum of Fire Fighting Systems.</CardText> 
									
									</CardBody>

									<CardFooter style={{backgroundColor:"#ffffff"}}>
											<NavLink to="/Services" >
												<img  src="../Image/Home/Readmoreicon00.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"  /> 
											</NavLink>
									</CardFooter>
								</Card>
								</div>



								<div className="p-2"   >
								<Card className="border border-secondary" style={{height: '28rem'}}> 
									<CardTitle className="p-3 bg-secondary text-white cardTitleFontFamily fs-5">Structural Work</CardTitle>
									<NavLink to="/Services" >
									<CardImg top width="100%" src="../Image/Services/Structralwork.jpg" alt="Card image cap" />
									</NavLink>
									<CardBody>
									
									<CardText className="cardFontFamily">Providing the best and fastest solutions for structural Design of Industrial Piping as per the Standards.</CardText> 
									
									</CardBody>
									<CardFooter style={{backgroundColor:"#ffffff"}}>
											<NavLink to="/Services" >
												<img  src="../Image/Home/Readmoreicon00.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"  /> 
											</NavLink>
									</CardFooter>
								</Card>
								</div>
							</div>
						</div>
						

						
				</div>
				<div className="row pt-2">
						<div className="col-md">
						<div className='d-flex'>

										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className='homeAboutIntroContentTitle'>
										Process
										</div>
										
								</div>
								<div className='d-flex'>
									<p className='homeAboutIntroContent'>
										JJ Associates is an industrial supporting firm established in 2015 with the objective to provide and offer high quality services in Fire Protection System, Industrial Piping System, Structural Work, HVAC Chill Water Piping System, AHU Piping, Structural Fabrication and Erection.
										
									</p>
									
								</div>
								<NavLink to="/Process" >
									<img src="../Image/Process/Processflow.jpg" className="img-fluid" alt="Process"  height="20px;" width="700px;" >
									</img>
								</NavLink>
								<span className='pt-2'>
											<NavLink to="/Process" >
												<img  src="../Image/Home/Readmoreicon00.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"  /> 
											</NavLink>
										</span>
								
</div>
								</div>
					<div className="row pt-2">
						<div className="col-md">
						<div className='d-flex'>

										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className='homeAboutIntroContentTitle'>
											Happy Clients
										</div>
								</div>
							<div className='d-flex'>
										<div className='homeAboutIntroContent' width="auto">
										<p className="display-5">Glad and proud to say that we have </p>
										<p className="display-2">50+ <br></br> </p>
										<p className="homeClientIntroContentTitle ml-5 ">Clients    
											<span className="ml-4">
												<NavLink to="/Clients"  >
													<img  src="../Image/Clients/Clientsreadiconfinal.jpg" alt="Card image" className="img-fluid" height="100px;" width="100px;"/> 
												</NavLink>
											</span>
										</p>

										</div>
										
									</div>
						</div>

						<div className="col-md">
						<NavLink to="/Clients">
							<img src="./Image/Clients/ClientsCollage.jpg" className="img-fluid"  width="500px;"/>
							</NavLink>
						</div>
					</div>
			   </div>
	        </>
	    )
	}
	
	export default Home;
	            