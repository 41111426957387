import React,  { useEffect }  from 'react';
	
function Gallery(){
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  });
	    return(
	        <>
			<div className="Container-fulid col-12">
						<div className="row col-max pt-2  ">
							<div className="col-md">
								<div className='d-flex'>
										<div className='homeClientsHeaderDivTop homeleftDiv'></div> 
										<div className="homeClientsHeaderDiv"></div>
										<div className="homeAboutIntroContentTitle">
											
												Gallery
											
										</div>
								</div>
								</div>
								</div>
								
<div className="row col-max pt-2">
  <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/01.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />

    <img
      src="../Image/Gallery/04.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/02.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Mountains in the Clouds"
    />

    <img
      src="../Image/Gallery/05.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/03.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Waves at Sea"
    />

    <img
      src="../Image/Gallery/06.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Yosemite National Park"
    />
	 </div>

<div className="col-lg-4 mb-4 mb-lg-0">
	   <img
      src="../Image/Gallery/07.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />

    <img
      src="../Image/Gallery/10.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Wintry Mountain Landscape"
    />

<img
      src="../Image/Gallery/13.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />
 
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/08.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Mountains in the Clouds"
    />

    <img
      src="../Image/Gallery/11.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />

<img
      src="../Image/Gallery/14.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Waves at Sea"
    />
	 
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/09.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Waves at Sea"
    />

    <img
      src="../Image/Gallery/12.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Yosemite National Park"
    />

<img
      src="../Image/Gallery/15.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Yosemite National Park"
    />
	</div>
 
  <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/16.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />
      <img
      src="../Image/Gallery/22.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />
    <img
      src="../Image/Gallery/17.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Wintry Mountain Landscape"
    />
  

    
  
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/18.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Mountains in the Clouds"
    />

    <img
      src="../Image/Gallery/19.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Boat on Calm Water"
    />
       <img
      src="../Image/Gallery/23.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="../Image/Gallery/20.jpg"
      className="w-100 shadow-1-strong rounded mb-4 border border-success"
      alt="Waves at Sea"
    />

    <img
      src="../Image/Gallery/21.jpg"
      className="w-100 h-600 shadow-1-strong rounded mb-4 border border-success"
      alt="Yosemite National Park"
    />
	 </div>

<div className="col-lg-4 mb-4 mb-lg-0">
	   

   

</div>
</div>
</div>

								
	    
	        </>
	    )
	}
	
	export default Gallery;

	

	            
	      