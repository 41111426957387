// import logo from './logo.svg';
import './App.css';
import {Routes,Route} from 'react-router-dom';
import Home from './Components/Home';
import Header from './Components/Header';
import About from './Components/About';
import Footer from './Components/Footer';
import Services from './Components/Services';
import Gallery from './Components/Gallery';
import Process from './Components/Process';
import Clients from './Components/Clients';
import Contact from './Components/Contact';
// import Navbarcustomnew from './Components/Navbarcustomnew';
import Navbarcustom from './Components/Navbarcustom';
import CarouselComponent from './Components/CarouselComponent';
import Scrollbutton from './Components/Scrollbutton';
import React from 'react';
<Home/>

function App() {
  return (
    <div className="App">
    
     <Header/>
     {/* <Navbarcustomnew/> */}
     <Navbarcustom/>
     <CarouselComponent/>
     <Routes >
                      <Route  path="/" element={<Home />}/>
                     <Route  path="/Home" element={<Home/>}/>
                     <Route  path="/About" element={<About/>}/>
                     <Route  path="/Services" element={<Services/>}/>
                     <Route  path="/Process" element={<Process/>}/> 
                     <Route  path="/Clients" element={<Clients/>}/>
                     <Route  path="/Gallery" element={<Gallery/>}/>
                     <Route  path="/Contact" element={<Contact/>}/>          
      </Routes>
      
      <Footer/>
      
    </div>
  );
}

export default App;
