import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Styles/Carousel.css';


 function CarouselComponent() {
    return (
        <div className="container-fluid nav-bg" >
            <div className="col-14 mx-auto">
        <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} >
                <div>
                    <img src="../Image/Carousel/Industrialpipingsystems.jpg" />
                </div>
                <div>
                    <img src="../Image/Carousel/fireproducation.jpg" />
                </div>
                 <div>
                    <img src="../Image/Carousel/Structrualwork .jpg" />
                </div>
            </Carousel>
        </div>
        </div>
        </div>
    );
} 

export default CarouselComponent;

