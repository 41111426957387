import React from 'react';

import './Styles/Footer.css'
	
	function Footer(){
	    return(
	        <>

<div className="contanier=fulid col-12">
         <div className="row">
         <div className="col-12 mx-auto container">
			<div className=" pt-2" >
				<div className="col-12 mx-auto footer-div-bgcolor-line">
					<div className="p-3 w-auto h-5 footer-div-bgcolor-line"> </div>
        		</div>
     		</div>

			 	<div className=" nav-bg " >
            			<div className="col-12 mx-auto footer-div-bgcolor">
							<div className="p-3   mb-2 container footer-font">
							<div className="row d-flex h-100 justify-content-center " >
								

                                    <div className="p-3  footer-div-list" >
                                        <h5 className="footer-div-content-title"  align="left">Contact</h5>
										<hr width="70x;"  color="white" size="5" align="left"></hr>
									
                                        <h6 className="footer-div-content-item"  align="left"><img src="../Image/Footer/footermobile.jpg"  className="rounded-circle" ></img>   9791389329 </h6>
										<h6 className="footer-div-content-item"  align="left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9894872020</h6>      
                                        <h6 className="footer-div-content-item"  align="left"><img src="../Image/Footer/whatsappicon.jpg"  className="rounded-circle" ></img>  9791389329  </h6>  
										<h6 className="footer-div-content-item"  align="left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9894872020</h6>  
                                        <h6 className="footer-div-content-item"   align="left"><img src="../Image/Footer/footermail.jpg"  className="rounded-circle"></img> jjassociates6@gmail.com </h6>
										
                                    </div>

								

									<div className="p-3  footer-div-list" > 
                                        <h5 className="footer-div-content-title"  align="left" >Address</h5>
										<hr width="70x;"  color="white" size="5" align="left"></hr>
                                        <h6 className="footer-div-content-item"  align="left">1/5 - 2nd Street,</h6>   
                                        <h6 className="footer-div-content-item"  align="left">Thirumalai Nagar, </h6>  
                                        <h6 className="footer-div-content-item"  align="left">Old Perungalathur, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
										<h6 className="footer-div-content-item"  align="left">Chennai - 600063,   </h6> 
										<h6 className="footer-div-content-item"  align="left">Tamil Nadu.   </h6> 
										    
                                    </div>

							
									<div className="p-3  footer-div-list">
										<div className='d-flex pt-3'>
										
										{/* <img src="../Image/Footer/Footerdoc.jpg" className="rounded-circle" alt="Responsive" width="100" height="100"></img> */}
										<img src="../Image/Footer/Footerresponsive.jpg" className="rounded-circle" alt="Responsive" width="100" height="100"></img>
										</div>

									</div>
									
							</div>
							<div className="row">
								<div className=" mx-auto container justify-Content-Center">
									<div className="pt-2">
										<h1 className="footer-div-content-item">
											&#169; JJ Associates 2023
										</h1>
									</div>
								</div>
							</div>
							</div>
						</div>
				</div>
				</div>
				</div>
				</div>				


	        </>
	    )
	}
	
	export default Footer;
