import React from "react";
import {NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap';

import useToggle from "./useToggle";

import "./Styles/Header.css";

function Navbarcustom(){
  const [isShown, toggle] = useToggle();

  function fnShowHide()
  {
    var navbtn = document.getElementById("responsive-navbar-nav");
    if(!isShown) 
    {
      navbtn.classList.remove("show");
    }
    else
    {
      navbtn.classList.add("show");

    }
  }

  function handleCollapse (){
    var nav = document.getElementById("responsive-navbar-nav");
    var btn = document.getElementById("btntoggler");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };


    return(
        <>
       <div className="contanier=fulid col-12">
         <div className="row">
         <div className="col-12 mx-auto container">
         

                      <Navbar collapseOnSelect expand="lg" className="JJAE-navbar-bg" variant="dark">
                      <LinkContainer to="/">
                          <Navbar.Brand href="/">
                          <img src="../Image/Profile 001.jpeg"></img>&nbsp; 
                          JJ Associates  
                          
                          </Navbar.Brand>
                      </LinkContainer>
                      <button id="btntoggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#responsive-navbar-nav" aria-controls="responsive-navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                          <Navbar.Collapse className="collapse navbar-collapse" id="responsive-navbar-nav" >
                            <Nav className="ml-auto text-left">
                            <NavLink  to="/Home"  className={(navData) => (navData.isActive ? "active nav-link " : 'nav-link')} onClick={handleCollapse} >Home</NavLink>
                            <NavLink to="/About" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')} onClick={handleCollapse} >About</NavLink>
                            <NavLink to="/Services" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')} onClick={handleCollapse} >Services</NavLink>
                            <NavLink to="/Process" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')}  onClick={handleCollapse}>Process</NavLink>
                            <NavLink to="/Clients" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')} onClick={handleCollapse}>Clients</NavLink>
                            <NavLink to="/Gallery" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')} onClick={handleCollapse}  >Gallery</NavLink>
                            <NavLink to="/Contact" className={(navData) => (navData.isActive ? "active nav-link" : 'nav-link')} onClick={handleCollapse}  >Contact</NavLink>
                        
                        
                          
                          </Nav>
                           </Navbar.Collapse>
                           </Navbar>
    </div>
    </div>
    </div>
    
    </>
    )
}

export default Navbarcustom;