import React from "react";
import "./Styles/Header.css";
function Header(){
return(
    <>
        <div className="container-fluid  col-12" >
                <div className="container col-12 mx-auto navbar-div-top">
                        <div className="row">
                            <div className="col-sm col-md col-lg text-left" >
                                <img src="../Image/Header/GST.jpg"></img>
                                 GST No : 33AXEPJ7501L1ZJ
                            </div>
                                <div className="col-sm col-md col-lg text-left">
                                    <img src="../Image/Header/mobile.jpg"></img>
                                    +91 9791389329   &nbsp;  +91 9894872020
                                </div>
                  
                                <div className="col-sm col-md col-lg text-left">
                                     <img src="../Image/Header/Location.jpg" ></img>
                                          Chennai
                                </div>
                        </div>
                </div>


                <div className ="contanier-fluid  mx-auto">
                        <div className="contanier-fluid">
                            <div className='navbar-div-top container'></div>
                            <div className='navbar-div-top1'></div>
                        </div>
                </div>
            
        </div>


    </>
)
}
export default Header;